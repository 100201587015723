.add-item-button {
    cursor: pointer;
}

.header-table{
    background-color: #204865;
    color: white;
}

th, td {
    padding: 8px;
}

tr {
    border-bottom: solid #dee8f0;
}

.validation-warning {
    color: red;
    font-size: small;
}

.action-icon {
    margin: 5px;
}

@media only screen and (max-width: 990px){
    .video-table-container {
        margin-left: 0em;
    }
}
