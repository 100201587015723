/*header*/
.site-logo-header-dashboard {
    margin-bottom: 30px;
    padding-left: 10px;
}

.container-nav {
    height: 100vh;
    width: 210px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    padding-top: 10px;
}

.container-nav .site-logo {
    margin-bottom: 30px;
}

.container-nav .nav-link {
    z-index: 100;
    width: 100%;
    color: rgba(8, 52, 84, 0.9);
}

.nav-item .nav-link {
    border-radius: 0px !important;
    font-size: medium;
    font-weight: bold !important;
    color: #294C67;
}

.nav-link.active {
    background-color: #79aeaa !important;
    color: white !important;
}


.container-nav .nav-link:hover,
.container-nav .nav-link.current {
    color: white;
    background: #79aeaa;
}

.btn-navbar-sm {
    display: none;
}

.header-logo {
    margin: 1em;
    width: auto;
    height: 104px;
}

.main-menu-a {
    color: #083454;
    font-weight: 400;
    margin-left: 12px;
    text-transform: uppercase;
    font-family: 'Playfair Display';
    text-align: center;
}

.main-menu-a:hover,
.main-menu-a.current {
    color: #79aeaa;
}

@media (max-width: 1220px) {
    .header-logo {
        width: 120px;
        height: 68px;
    }
}

@media (min-width: 1221px) and (max-width: 1400px) {
    .header-logo {
        width: 144px;
        height: 81px;
    }
}

@media only screen and (min-width : 768px) and (max-width : 1200px) {
    .fixed-container-sm {
        background: white;
        margin-top: -10px;
        max-width: 100% !important;
    }

    .container-sm-header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        z-index: 100;
    }
}

@media (max-width: 768px) {

    /*header*/
    .fixed-container-sm {
      background: white;
      margin-top: -10px;
      max-width: 100vw !important;
    }

}  