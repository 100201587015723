html {
  max-width: 100%;
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 100%;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none;
}

.bt-green-def {
  background-color: #79aeaa;
  border: none;
  padding: 12px;
  color: white;
  text-decoration: none;
  cursor: pointer;
  width: fit-content;
}

.bt-green-def:hover {
  background-color: #588783;
}

.bt-blue-def {
  background-color: #294C67;
  border: none;
  padding: 12px;
  color: white;
  text-decoration: none;
  cursor: pointer;
  width: fit-content;
}

.bt-blue-def:hover {
  background-color: #79aeaa;
}

.small-margin-right {
  margin-right: 8px;
}

.clickable {
  cursor: pointer;
}

.hiddenTooltip {
  display: none;
}

.visibleTooltip {
  display: block;
  position: absolute;
  width: 100px;
  font-size: 12px;
  color: #083454;
  margin-top: -20px;
}

.fixed-container {
  position: fixed;
  left: 0;
  top: 0;
  background: white;
  display: block;
  width: 100vw;
  z-index: 1000;
  height: 130px;
  margin-top: -15px;
}

.site-logo {
  width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.link-login {
  text-align: center;
  color: #083454;

}



.container-header-main {
  display: flex;
  justify-content: center;
  align-items: center;
}

.link-login:hover {
  text-decoration: none;
  color: #79aeaa;
}

.material-icons-login {
  font-size: 40px;
}

.material-icons-info {
  font-size: 32px;
  color: #79aeaa;
}

.no-current {
  color: blue;
}

.navbar-toggler-icon {
  color: black !important;
}

/*footer*/
.footer-color-background {
  border-top: 1px solid #92c8c4;
}

.container-eps-arl {
  display: flex;
  justify-content: space-around;
}

.container-col-1-logos {
  display: flex;
  justify-content: space-around;
}

.iledefrance {
  width: 40%;
}

.img-urps {
  margin: 1em;
  width: 170px;
  height: auto;
  margin-left: -40px;
}

.img-arl {
  margin: 1em;
  width: 100px;
  height: auto;
}

.img-rtc {
  margin: 1em;
  width: 170px;
  height: auto;
  margin-left: -40px;
}

.img-qrc {
  margin: 1em;
  width: 120px;
  height: 120px;
}

.img-iledefrance {
  margin: 1em;
  width: 190px;
  height: auto;
  margin-left: -5px;
}

.color-letter-footer {
  color: #083454 !important;
}

.linea-divisora-footer {
  width: 10%;
  border: 1px solid #92c8c4;
}

.color-green-main {
  color: #79aeaa;
}

.button-newletter,
.button-newletter-sm {
  background-color: #79aeaa;
  border: none;
  width: 220px;
  padding: 12px;
  color: white;
  text-decoration: none;
  box-shadow: 2px 2px 30px 5px rgba(0, 0, 0, 0.4);
}

.container-btn-newletter {
  margin-top: 15px;
  margin-bottom: 15px;
  display: flex;
  justify-content: flex-start;
}

.button-newletter:hover,
.button-newletter-sm:hover {
  text-decoration: none;
  color: white;
  box-shadow: 5px 5px 30px 5px rgba(0, 0, 0, 0.4);
}

.button-newletter-sm {
  display: none;
}

.btn-style-form-contact {
  background-color: #79aeaa;
  border: none;
  border-radius: 0%;
  width: 200px;
  padding: 8px;
  color: white;
}

.color-letters-link {
  color: #92c8c4;
  font-weight: 600;
}

.form-control {
  border: 1px solid rgba(8, 52, 84, 0.4);
  color: rgba(8, 52, 84, 1);
}

.btn-style-form-contact:hover,
.btn-style-form-contact:active,
.btn-style-form-contact:focus,
.btn-style-form-contact:visited {
  background-color: #79aeaa;
  box-shadow: 2px 2px 30px 5px rgba(0, 0, 0, 0.4);
}

/*home*/
.color-green-hover:hover {
  color: #79aeaa;
  text-decoration: none;
}

.routes-bus {
  display: block;
}

.routes-bus-sm {
  display: none;
}

.btn-center-health {
  background: rgba(8, 52, 84, 0.9);
  border: 1px solid rgba(8, 52, 84, 1);
  color: white;
  text-decoration: none;
  padding: 8px;
  font-family: 'Ubuntu';
  font-size: 18px;
  box-shadow: 2px 2px 30px 5px rgba(0, 0, 0, 0.4);
  font-family: 'Playfair Display';
}

.btn-center-health:hover {
  text-decoration: none;
  color: white;
  box-shadow: 2px 2px 15px 5px rgba(8, 52, 84, 1);
}

.header-main-section-two {
  text-align: center;
  margin-top: 5px;
  margin-bottom: 50px;
  width: 100%;
  color: #79aeaa;
  font-family: 'Playfair Display';
}

.subtitle-header {
  color: #79aeaa;
}

.item-info-blue {
  color: #083454;
}

.item-info-green {
  color: #79aeaa;
}

.container-tarif {
  margin-bottom: 50px;
}

.background-color-card {
  border: 1px solid rgba(8, 52, 84, 0.9);
}

.container-info-practice {
  width: 35vw;
  text-align: left;
}

.container-info-home {
  max-width: 100vw;
  display: flex;
  justify-content: center;
}

.container-ligne-bus {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.color-purple,
.color-orange,
.color-blue-light,
.color-white,
.color-blue-dark {
  background: #772e79;
  color: white;
  padding: 5px;
  border-radius: 5px;
  margin-left: 2px;
}

.color-orange {
  background: #ffa500;
}

.color-blue-light {
  background: #a1ceed;
  color: black;
}

.color-white {
  background: white;
  border: 1px solid black;
  color: black;
}

.color-blue-dark {
  background: #0000f9;
}

.circle-yellow,
.circle-green {
  border: 3px solid #e5be01;
  color: #e5be01;
  padding: 3px 10px;
  border-radius: 50%;
  margin-left: 5px;
}

.circle-green {
  border: 3px solid #007f2a;
  color: #007f2a;
}

.center-map {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50vw;
}

/*contact*/
.validation-error {
  color: red;
  font-size: small;
  transform: translateY(-8px);
}

.info-contact {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 50px;
  font-family: 'Playfair Display';
  font-size: 17px;
  color: rgba(8, 52, 84, 0.9);
  text-align: center;
}

.container-info {
  width: 90%;
  color: rgba(8, 52, 84, 1);
}

.container-main-contact {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 110px;
  margin-bottom: 50px;
}

.title-contact {
  font-family: 'Playfair Display';
  color: #79aeaa;
  text-align: center;
  margin-bottom: 30px;
}

.text-info-contact {
  font-family: 'Playfair Display';
  font-size: 17px;
  color: rgba(8, 52, 84, 0.9);
  text-align: center;
  margin-bottom: 50px;
}

.color-green-contact {
  color: #79aeaa;
}

.alerta-success {
  display: none;
}

/*team*/
.text-header-team-color-blue,
.text-header-team-color-green {
  text-align: left;
  font-size: 27px;
  border-bottom: 1px solid rgba(8, 52, 84, 0.9);
}

.button-link-team {
  background: #79aeaa;
  padding: 10px;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
}

.button-link-team a {
  color: white;
  text-decoration: none;
}

.button-link2-team {
  background: rgba(8, 52, 84, 0.9);
  padding: 10px;
  font-weight: bold;
  color: white;
  border-radius: 5px;
  cursor: pointer;
}

.button-link2-team a {
  color: white;
  text-decoration: none;
}

.button-link-team:hover {
  background: rgba(8, 52, 84, 0.9);
}

.button-link2-team:hover {
  padding: 15px;
}

.text-header-team-color-green {
  border-bottom: 1px solid #79aeaa;
}

.img-medicine-speciallity {
  width: 256px;
  height: 300px;
  margin-bottom: 10px;
}

.container-info-team {
  text-align: center;
  font-size: 18px;
  margin: 30px 0px;
}

.img-medicine {
  border: 2px solid #79aeaa;
}

.container-img-team {
  width: 100%;
  text-align: center;
  margin-bottom: 50px;
  margin-top: 70px
}

.container-col {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
}

.container-description-team {
  width: 50%;
  text-align: left;
}

.color-blue {
  color: rgba(8, 52, 84, 0.9);
}

.color-green {
  color: #79aeaa;
}

.btn-add-page-link {
  text-align: right;
}

.btn-add-page-link img {
  width: 50px;
}

/*updates*/
.hiddenInput {
  display: none;
}

.container-updates {
  margin-top: 60px;
}

.visibleInput {
  display: inline-block;
  width: 200px;

}

.container-card-post {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  text-align: center;
  margin-bottom: 30px;
}

.text-card-post {
  text-align: justify;
  padding: 0px 15px;
}

.section-event-cards {
  max-width: 100vw;
  display: flex;
  justify-content: flex-start;
  margin-top: 30px;
  margin-bottom: 50px;
}

.link-card {
  max-width: 100%;
  display: flex;
  justify-content: flex-start;
  margin-top: -18px;
}

.container-views {
  display: flex;
  justify-content: center;
  align-items: center;
}

.title-card-post {
  max-width: 100%;
  text-align: left;

}

.link-title-card {
  color: rgba(8, 52, 84, 0.9);
  text-decoration: none;
}

.link-title-card:hover {
  color: #79aeaa;
}

.search {
  font-size: 18px;
  color: rgba(8, 52, 84, 0.9);
}

/*login*/
.background-container-login {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.container-login {
  border: 1px solid rgba(121, 174, 170, 0.5);
  padding: 30px;
  font-family: 'Playfair Display';
  color: #083454;
}

.col-size-input-login {
  width: 450px;
}

.btn-style-form {
  background-color: #79aeaa;
  border: none;
  border-radius: 5%;
  width: 100px;
  padding: 10px;
  color: white;
  text-align: right;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-style-form:hover {
  background-color: #79aeaa;
  color: white;
  box-shadow: 2px 2px 2px 1px rgba(121, 174, 170, 0.5);
}

.container-password {
  width: 55vw;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 200px;
  margin-bottom: 50px;
}

.link-password {
  color: #79aeaa;
}

.link-password:hover {
  color: rgba(121, 174, 170, 0.5);
  text-decoration: none;
}

.align-center-button {
  display: flex;
  justify-content: flex-end;
}

.title-login {
  font-family: "Playfair Display";
  text-align: center;
  width: 90vw;
  font-size: 20px;
  margin-bottom: 20px;
  color: #083454
}


.card-list-post {
  margin: 35px;
  width: 300px;
  height: 500px;
  position: relative;
  float: left;
}

/*post*/
.container-post {
  width: 55vw;
  padding-bottom: 40px;
  margin-bottom: 50px;
  margin-top: 90px;
  color: rgb(8, 52, 84);
}

.title-post {
  text-align: center;
  font-family: 'Julius Sans One';
  margin-bottom: 30px;
  margin-top: 30px;

}

.container-img-post {
  height: 350px;
  width: 380;
  max-width: 380;
  margin: 0px 15px;
}

.img-post {
  height: inherit;
  width: fit-content;
  max-width: 90%;
  object-fit: contain;
}

.paragraph-editor {
  text-align: justify;
  width: 700px;
}

.container-checkbox-published {
  width: 100%;
  margin-bottom: 1em;
}

/*policy*/

.container-policy,
.container-legaly {
  margin-top: 90px;
  padding: 50px 100px;
  text-align: justify;
}

.container-policy h1,
.container-policy h5,
.container-legaly h1,
.container-legaly h5 {
  text-align: center;
  color: #083454;
  margin-bottom: 50px;
  margin-top: 50px;
}

.padding-botton-title {
  padding: 30px 0px;
}

.list-type-1 {
  list-style: disc;
}

.list-type-1 b {
  color: #083454;
}

.list-type-2 {
  list-style: none;
}

.list-type-3 {
  list-style: lower-alpha;
}

.text-indent {
  padding-left: 50px;
}

/*dashboard*/

/*User*/
.container-user {
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  margin: 15px;
  color: rgba(8, 52, 84, 0.9);
}

.logout-sm-menu {
  display: none;
}

.a-dropdown {
  color: rgba(8, 52, 84, 0.9) !important;
  text-decoration: none;
  font-size: 20px;
  text-transform: capitalize;
  font-family: 'Julius Sans One';
}

.a-dropdown:hover {
  text-decoration: none;
  text-shadow: 1px 1px rgba(8, 52, 84, 0.3);
}

/*datatable*/
.container-btn-create-post {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

.btn-create-post {
  margin-left: 50px;
}

.hidden-btn-create {
  display: none;
}

.container-datatable {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-left: 40px;
}

.table-container {
  display: flex;
  margin-top: 7em;
  margin-left: 1em;
}

.container-table {
  max-width: 100%;
  margin-top: 100px;
  overflow-x: scroll;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.alert-datatable {
  width: 90%;
}

.table-datatable {
  border-collapse: collapse;
  display: table-row;
  width: auto;
}

.column-datatable {
  background: rgba(8, 52, 84, 0.9);
  color: white;
  border: 1px solid white;
  text-align: center;
  padding: 10px;
}

.full-width {
  width: 100%;
}

.half-width {
  width: 50%;
}

.col-10 {
  width: 10%;
}

.col-15 {
  width: 15%;
}

.col-20 {
  width: 20%;
}

.col-25 {
  width: 25%;
}

.col-30 {
  width: 30%;
}

.col-40 {
  width: 40%;
}

.col-60 {
  width: 60%;
}


.hiddenColumn {
  display: none;
}

.visibleColumn {
  display: block;
  display: table-cell;
}

.row-datatable {
  max-width: 600px;
}

.row-datatable td {
  padding: 6px;
  text-align: center;
  border-bottom: 1px solid rgba(8, 52, 84, 0.9);
  font-size: 14px;
}

.row-datatable td:nth-child(2) {
  text-align: center;
}

.btn-view-database,
.btn-edit-database,
.btn-delete-database,
.btn-create-post,
.btn-check,
.btn-remove {
  color: rgba(8, 52, 84, 0.9);
  margin-right: 5px;
  cursor: pointer;
}

.btn-edit-database-disabled {
  color: #79aeaa;
}

.paragraph-editor-preview {
  width: 100%;
}

.title-editor-preview {
  text-align: center;
  font-family: 'Julius Sans One';
  width: 100%;
}

.btn-view-database:hover,
.btn-edit-database:hover,
.btn-delete-database:hover,
.btn-create-post:hover,
.btn-check:hover,
.btn-remove:hover {
  color: #79aeaa;
  text-decoration: none;
}

.size-icono {
  font-size: 30px;
}

.size-icono-add {
  font-size: 40px;
}

.disabled-icon {
  color: gray;
}

.container-pagination {
  margin-top: 30px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.pagination {
  margin: 15px auto;
  display: flex;
  list-style: none;
  outline: none;
}

.pagination>.active>a {
  background-color: rgba(8, 52, 84, 0.9);
  border-color: rgba(8, 52, 84, 0.9);
  color: #fff;
}

.pagination>li>a {
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
}

.pagination>.active>a,
.pagination>.active>span,
.pagination>.active>a:hover,
.pagination>.active>span:hover,
.pagination>.active>a:focus,
.pagination>.active>span:focus {
  background-color: rgba(8, 52, 84, 0.9);
  border-color: rgba(8, 52, 84, 0.9);
  outline: none;
}

.pagination>li>a,
.pagination>li>span {
  color: rgba(8, 52, 84, 0.9);
}

.pagination>li:first-child>a,
.pagination>li:first-child>span,
.pagination>li:last-child>a,
.pagination>li:last-child>span {
  border-radius: unset
}

/*error page*/
.container-error-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.container-img-error-page {
  width: 500px;
  height: 500px
}

/*alert*/
.alert-hide {
  display: none;
}

.alert-visible {
  margin-top: 200px;
  display: block;
}

.alert-info-visible {
  display: block;
}

/*editor*/

.ck-content {
  border: 1px solid rgba(8, 52, 84, 0.3) !important;
}

.container-editor {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 40px;
  margin-top: 30px;
  color: rgb(8, 52, 84);
}


.container-editor h1 {
  margin: 20px 0px;
  text-align: center;

}

.container-editor h5 {
  margin: 20px 0px;
}

.editor-padding {
  margin-left: 35px;
}

.btn-style-form-newsletter {
  background-color: #79aeaa;
  border: none;
  border-radius: 0%;
  width: 200px;
  padding: 8px;
  color: white;
}

.cita-editor {
  border-left: 5px solid #79aeaa;
  background: rgba(121, 174, 170, 0.1);
  padding: 3px 5px 10px 10px;
  font-style: italic;
}

.title-editor {
  text-align: center;
  font-family: 'Julius Sans One';
  margin-bottom: 30px;
  margin-top: 30px;
}

.paragraph-editor {
  text-align: justify;
}

.container-video-editor {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.video-editor {
  max-width: 90vw;
}

.container-category-published {
  margin: 30px 0px;
}

.inputState {
  width: 100px !important;
}

.container-category-published input {
  margin-right: 10px;
}

.container-category-published select {
  margin-top: 8px;
  padding: 12px 22px 12px 22px;
  border: solid #92c8c4 1px;
}

.container-button-editor {
  display: flex;
  justify-content: flex-end;
}


.col-post {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding-top: 50px;
}

.password-dashboard-change {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

/*team dashboard*/
.container-team-dashboard {
  display: flex;
  justify-content: center;
}

.subcontainer-team-dashboard {
  width: 75%;
  margin-left: 40px;
  margin-top: 100px;
}

.img-post-view {
  width: 600px;
  margin-bottom: 30px;
}


@media only screen and (min-width : 768px) and (max-width : 1200px) {
  /*policy*/
  .container-policy,
  .container-legaly {
    padding: 30px;
    margin-top: 100px;
  }

  .form-group,
  .form-control,
  .col-size-input-login {
    z-index: -1 !important;
  }

  /*upodate*/
  .container-img-post {
    height: 270px;
  }

  .img-post {
    height: 270px;
  }

  /*contact*/
  .container-main-contact {
    margin-top: 140px;
  }

  /*team*/
  .container-description-team {
    width: 80%;
  }


  /*dashboard*/
  /*header*/
  .btn-navbar-sm {
    display: block;
    position: absolute;
    border: none;
    margin: 10px;
    z-index: 100;
  }

  .container-nav {
    display: none;
  }

  .container-nav-sm {
    height: 100vh;
    width: 90vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: white !important;
    z-index: 100;
  }



  .container-nav-sm .site-logo {
    margin-bottom: 30px;
    z-index: 100;
  }

  .container-nav-sm .nav-link {
    color: rgba(8, 52, 84, 0.9);
    width: 100%;
    text-align: center;
    font-size: 20px;
    z-index: 100;
  }

  .container-nav-sm .nav-link:hover {
    font-size: 25px;
    z-index: 100;
  }

  .container-nav-sm .nav-link:active {
    border: none;
    z-index: 100;
  }

  .hiden-img-menu {
    display: none;
    position: static;
  }

  .visible-img-menu {
    display: block;
  }

  .hiden-span-menu {
    display: none;
  }

  .visible-span-menu {
    display: block;
    position: absolute;
  }

  .close-menu {
    color: black;
    font-family: 'Julius Sans One';
    font-size: 35px;
  }

  .container-table {
    width: 90vw;
    margin-top: 100px;
    overflow-x: scroll;
  }

  .container-datatable {
    padding-left: 0px;
  }

  .table-datatable {
    width: 95vw;
    margin-left: 0;
    border-collapse: collapse;
    display: table-row;
  }

  .container-column-sm th:nth-child(1),
  .row-datatable td:nth-child(1) {
    padding-left: 30px;
  }

  /*user*/
  .container-user {
    display: none;
  }

  .logout-sm-menu {
    display: block;
  }

  .container-pagination {
    width: 90vw;
  }

  /*editor*/
  .container-editor {
    width: 100%;
    z-index: -1;
    margin-top: 50px;
  }

  .editor-padding {
    margin-left: 0px;
    margin-top: 50px;
  }

  /*password*/
  .password-dashboard-change {
    margin-top: 0px;
  }

  /*teamm dashboard*/
  .container-team-dashboard {
    display: flex;
    justify-content: center;
    flex-direction: column;
    z-index: -1;
  }

  .subcontainer-team-dashboard {
    width: 100%;
    margin-left: 0;
    margin-top: 150px;
  }

  /*post*/

  .paragraph-editor {
    width: 500px;
  }

  .img-post-view {
    width: 500px;
  }

  .col-post {
    z-index: -1;
  }

  /*btn create post*/
  .btn-create-post {
    margin-left: 0px;
  }

  /*info practice home*/
  .container-info-practice {
    width: 50vw;
  }

  /*footer*/
  .img-urps {
    margin-left: 30px;
    margin-right: 5px;
    width: 130px;
  }

  .img-arl {
    width: 70px;
  }

  .img-iledefrance {
    margin-left: 0px;
    width: 170px;
  }

  .img-rtc {
    margin: 1em;
    width: 170px;
    height: auto;
  }
  
  .img-qrc {
    margin: 1em;
    width: 120px;
    height: 120px;
  }
}


@media (max-width: 768px) {

  /*footer*/
  .container-eps-arl {
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  /*policy*/
  .container-policy,
  .container-legaly {
    margin-top: 100px;
    padding: 30px;
  }

  /*login*/

  .container-login {
    width: 90vw;
  }

  .col-size-input-login {
    width: 80vw;
  }

  .title-login {
    width: 90vw;
    text-align: left;
    font-size: 18px;
  }

  .margin-bottom-sm {
    margin-bottom: 25px;
  }

  .main-menu-a {
    border: none;
    text-align: center;
  }

  .main-menu-a:hover,
  .main-menu-a.current {
    border: none;
  }

  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  /*footer*/
  .row #logo-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
  }

  .img-urps {
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 5px;
  }

  .img-arl {
    margin-bottom: 5px;
  }

  .img-iledefrance {
    margin: 1em;
    width: 190px;
    height: auto;
    margin-left: 40px;
  }

  .img-rtc {
    margin: 1em;
    width: 170px;
    height: auto;
  }
  
  .img-qrc {
    margin: 1em;
    width: 120px;
    height: 120px;
  }

  .container-col-1-logos {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .button-newletter {
    display: none;
  }

  .row .button-newletter-sm {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
  }

  .routes-bus {
    display: none;
  }

  .routes-bus-sm {
    display: block;
  }

  /*contact*/
  .info-contact {
    display: none;
  }

  /*post*/
  .container-post {
    width: 100vw;
  }

  .img-post-view {
    width: 200px;
  }

  /*team*/
  .container-description-team {
    width: 60%;
  }

  /*dashboard*/
  /*header*/
  .btn-navbar-sm {
    display: block;
    position: absolute;
    border: none;
    z-index: 1;
    margin: 10px;
  }

  .container-nav {
    display: none;
  }

  .container-nav-sm {
    height: 100vh;
    width: 90vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: white !important;
  }

  .container-nav-sm .site-logo {
    margin-bottom: 30px;
  }

  .container-sm-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .container-logo-icon-login {
    display: flex;
    justify-content: space-between;
    width: 310px !important;
  }

  .site-logo {
    width: 280px;
    margin: 0;
    padding: 0;
  }
  
  .container-nav-sm .nav-link {
    color: rgba(8, 52, 84, 0.9);
    width: 100%;
    text-align: center;
    font-size: 20px;

  }

  .container-nav-sm .nav-link:hover {
    font-size: 25px;
  }

  .container-nav-sm .nav-link:active {
    border: none;
  }

  .hiden-img-menu {
    display: none;
    position: static;
  }

  .visible-img-menu {
    display: block;
  }

  .hiden-span-menu {
    display: none;
  }

  .visible-span-menu {
    display: block;
    position: absolute;
  }

  .close-menu {
    color: black;
    font-family: 'Julius Sans One';
    font-size: 35px;
  }

  /*datatable*/

  .container-datatable {
    padding-left: 0px;
  }

  .container-pagination {
    width: 90vw;
  }

  .container-table {
    width: 90vw;
    margin-top: 100px;
    overflow-x: scroll;
  }

  .table-datatable {
    width: 95vw;
    margin-left: 0;
    border-collapse: collapse;
    display: table-row;
  }

  .container-column-sm th {
    padding: 10px 3px;
  }

  .container-column-sm th:nth-child(1),
  .row-datatable td:nth-child(1) {
    padding-left: 20px;
  }

  /*btn create post*/
  .btn-create-post {
    margin-left: 0px;
  }

  /*user*/
  .container-user {
    display: none;
  }

  .logout-sm-menu {
    display: block;
  }

  /*editor*/
  .container-editor {
    width: 100%;
    z-index: -1;
    margin-top: 50px;
  }

  .editor-padding {
    margin-left: 0px;
    margin-top: 50px;
  }

  /*password*/
  .password-dashboard-change {
    margin-top: 0px;
  }



  /*teamm dashboard*/
  .container-team-dashboard {
    display: flex;
    justify-content: center;
    flex-direction: column;
    z-index: -1;
  }

  .subcontainer-team-dashboard {
    width: 100%;
    margin-left: 0;
    margin-top: 150px;
  }

  /*post*/
  .paragraph-editor {
    width: 340px;
  }

  .img-post-view {
    width: 300px;
  }

  .col-post {
    z-index: -1;
  }

  /*contact*/
  .container-main-contact {
    margin-top: 150px;
  }

  /*updates*/
  .container-updates {
    margin-top: 170px;
  }

  /*home*/
  .container-info-practice {
    width: auto;
    padding-left: 10;
  }

  /*map*/
  .center-map {
    width: 100vw;
    margin-top: 20px;
    margin-bottom: 40px;
  }

  /*password*/
  .container-password {
    margin-top: 100px;
  }



}