.subheader{
    width: max-content;
    height: auto;
    vertical-align: bottom;
    margin-top: 8em;
    overflow: hidden;
    display: flex;
}

.slider {
    width: 100%;
    height: auto;
    margin: auto;
    overflow: hidden;
    background-color: #083453;
}

.slider .slide-track {
    display: flex;
    animation: scroll 40s linear infinite;
    -webkit-animation: scroll 40s linear infinite;
    width: auto;
}

.slider .slide {
    width: auto;
    margin: 1em;
    overflow: hidden;
    display: flex;
}

.slide span {
    color: white;
    font-weight: bold;
    font-size: large;
}

@keyframes scroll {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
    100% {
        -webkit-transform: translateX(-4500px);
        transform: translateX(-4500px);
    }
}