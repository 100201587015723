.landing-container {
    width: 100%;
    margin-right: 0px !important;
    margin-left: 0px !important;
}

.ophours-container {
    padding: 2em;
}

.small-margin-top {
    margin-top: 0px;
}

.container-jumbotron {
    width: 100%;
    height: 700px;
    margin-top: -64px;
    margin-bottom: -64px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: 'Playfair Display';
}

.jumbotron {
    border-radius: 0%;
    padding: 2rem 1rem;
}

.col-md-6 {
    padding-right: 4px;
    padding-left: 4px;
}

.full-size {
    width: 100%;
    height: 100%;
}

.ophours-title {
    color: #083453;
    text-transform: uppercase;
    padding: 1em;
    text-align: center;
}

.button-primary {
    background-color: #79aeaa;
    border: none;
    padding: 12px;
    color: white;
    text-decoration: none;
    box-shadow: 2px 2px 10px 2px rgba(121, 174, 170, 0.6);
    cursor: pointer;
    width: fit-content;
}

.button-primary:hover {
    text-decoration: none;
    color: white;
    box-shadow: 4px 4px 15px 4px rgba(121, 174, 170, 0.6);
}

.button-secondary {
    background-color: rgba(8, 52, 84, 1);
    border: none;
    padding: 12px;
    color: white;
    text-decoration: none;
    box-shadow: 2px 2px 10px 2px rgba(121, 174, 170, 0.6);
    cursor: pointer;
    width: fit-content;
}

.button-secondary:hover {
    text-decoration: none;
    color: white;
    box-shadow: 4px 4px 15px 4px rgba(121, 174, 170, 0.6);
}

.button-primary img {
    height: 15px;
    vertical-align: middle;
    width: auto;
}

.button-primary span {
    font-size: 16px;
}

.punch-title {
    font-family: 'Playfair Display';
    color: #083453;
    text-align: center;
}

.text-presentation {
    text-align: justify;
    margin-bottom: 30px;
    color: rgba(8, 52, 84, 1);
    font-size: 17px;
    padding: 30px 50px 30px 50px;
    background-color: #ffe64144;
}

.counter-section {
    padding: 2em;
    opacity: 0;
    transition: opacity 2.5s ease;
}

.counter-section.visible {
    opacity: 1;
}

.counter {
    padding: 2em;
    width: 300px;
    align-items: center;
    text-align: center;
    box-shadow: 8px 8px 15px 4px rgba(121, 174, 170, 0.6);

}

.counter h3 {
    color: rgba(121, 174, 170, 1);
}

.video-section {
    padding: 1em;
    transition: opacity 2.5s ease;
}

.video-container img {
    border-radius: 4px;
    cursor: pointer;
    justify-content: center;
    align-items: center;
}

.video {
    width: 100%;
    align-items: center;
    text-align: center;
}

.video-text {
    color: #083453;
    text-align: center;
}

.custom-swiper {
    position: relative;
}

.swiper-pagination-bullets {
    bottom: 10px !important;
}

.custom-swiper .swiper-pagination {
    left: 0;
    right: 0;
    text-align: center;
}

.swiper-slide-active {
    width: 100% !important;
}

.custom-button {
    height: 32px;
    padding: 8px;
    background-color: white;
    border: solid 1px #083453;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.swiper-button-prev:hover,
.swiper-button-next:hover {
    background-color: rgba(225, 237, 239, 0.882);
}

@media (min-width: 576px) {
    .jumbotron {
        padding: 4rem 2rem;
    }
}