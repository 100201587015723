.row-align-right {
    text-align: end;
}

.btn-style-green-default {
    background-color: #79aeaa;
    border: none;
    border-radius: 0%;
    width: 100%;
    padding: 8px;
    color: white;
}

.btn-style-blue-default {
    background-color: #083454;
    border: none;
    border-radius: 0%;
    width: 200px;
    padding: 8px;
    color: white;
}

.btn-style-red-default {
    background-color: red;
    border: none;
    border-radius: 0%;
    width: 100%;
    padding: 8px;
    color: white;
}
  
.required-warning {
    color: red;
    font-size: small;
    margin-top: -8px;
    margin-bottom: 6px;
}

.btn-confirm {
    background-color: #79aeaa;
    border: none;
    border-radius: 0%;
    width: 100%;
    padding: 8px;
    color: white;
}

.button-input-file-style {
    background: rgba(8, 52, 84, 0.9);
    color: white;
    padding: 4px;
    border-radius: 5%;
    cursor: pointer;
}